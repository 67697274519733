import { getCache, getUserInfo, updateUser } from '../../api/list';
import { Cell, CellGroup } from 'vant';
import { Toast } from 'vant';
import { Uploader } from 'vant';
export default {
  setup() {
    const afterRead = file => {
      // 此时可以自行将文件上传至服务器
      console.log(file); // this.fileList.add(file);

      const maxSize = file.type === 'image/jpeg' ? 500 * 1024 : 1000 * 1024;
      return file.size >= maxSize;
    };

    return {
      afterRead
    };
  },

  name: 'Account',

  data() {
    return {
      member: {
        nickName: '',
        phone: '',
        pwd: '',
        pwd2: '',
        motto: '',
        headImg: '',
        email: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      headImg: '',
      fileList: [],
      // 图片预览地址
      showflag: true
    };
  },

  created() {
    console.log('init');
    this.initData();
  },

  methods: {
    initData() {
      getUserInfo().then(res => {
        console.log(res.data);
        this.member = res.data;
      });
    },

    submit() {
      console.log(this.fileList);
      this.fileList.forEach(item => {
        this.member.headImg = item.content;
        console.log(this.member.headImg);
      });
      updateUser(this.member).then(res => {
        console.log(res);
        this.initData();
        Toast('更新成功');
      });
    },

    handleAvatarSuccess(res, file) {
      console.log('上传成功');
      this.imageUrl = URL.createObjectURL(file.raw);
    },

    beforeAvatarUpload(file) {
      console.log('上传前检查', file.type);
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 6;

      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 6MB!');
      }

      return isJPG && isLt2M;
    },

    handleRemove(file) {
      console.log(file);
      this.showflag = true;
    },

    handlePictureCardPreview(file) {
      console.log('preview');
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleupload(param) {
      console.log('添加图片');
      this.fileList.push(param.file); // 一般情况下是在这里创建FormData对象，但我们需要上传多个文件，为避免发送多次请求，因此在这里只进行文件的获取，param可以拿到文件上传的所有信息

      this.showflag = false;
    }

  }
};